import config from "config";
import axios from "axios";

const Api = {
  checkSessionAccess(email, contentId) {
    return this.newReqPortal("get", `/user/sessionAccess?email=${email}&contentId=${contentId}`)
  },
  postTrackerData(data) {
    return this.newReqPortal("post", "user/watching", data);
  },
  postPlaybackTrackerData({ alias, email, currentTime }) {
    // return this.newReqPortal("get", `tracking/playback?alias=${alias}&email=${email}&currentTime=${currentTime}`);
    return this.newReqPortal("get", `tracking/playback-v2?data=${alias},${email},${currentTime}`);
  },
  getChatPassword(email) {
    return this.newReqPortal("post", "chat1/user", { email }).then(x => x.data);
  },
  uploadChatFile(file) {
    const formData = new FormData();
    formData.append("file", file);
    return this.newReqPortal("post", "chat1/file", formData).then(x => x.data);
  },

  makeAssetUrl(path) {
    if (!path)
      return null;
    if (path.includes("https://"))
      return path;
    return `${config.apiSpmHost}${path}`;
  },

  makeSlideUrl(contentId, slideNo, size, userId) {
    return `https://ssp-blobs-cdn.azureedge.net/presentations/${userId}/${contentId}/${size}/slide${slideNo}.png?x=1`;
    // return `https://sspwebapi2.azureedge.net/api/presentation/${contentId}/slide/${slideNo}?size=${size}`;
  },

  makeSlideFallbackUrl(contentId, slideNo, size, userId) {
    return `https://sspdata.blob.core.windows.net/presentations/${userId}/${contentId}/${size}/slide${slideNo}.png?x=1`;
  },

  makeVideoUrl(contentId, fileName, userId) {
    return `https://ssp-blobs-cdn.azureedge.net/presentations/${userId}/${contentId}/video/${fileName}?x=1`;
    // return `https://slidespielspeakersapi2/api/presentation/${contentId}/video/${fileName}`;
    // return `https://sspspmapi.azureedge.net/api/presentation/${contentId}/video/${fileName}`;
    // return `https://slidespielspeakersapi.azurewebsites.net/api/presentation/${contentId}/video?name=${fileName}`;
    // return `https://sspwebapi2.azureedge.net/api/presentation/${contentId}/video?name=${fileName}`;
  },

  makeVideoFallbackUrl(contentId, fileName, userId) {
    return `https://sspdata.blob.core.windows.net/presentations/${userId}/${contentId}/video/${fileName}?x=1`;
  },

  makeSubtitlesUrl(userId, recordingId) {
    return this.makeAssetUrl(`/api/recording/${recordingId}/subtitles`);
  },

  makeAudioUrl(recordingId, format = "mp3", noCache = false) {
    if (format === "mp3") {
      return this.makeAssetUrl(`/api/recording/${recordingId}/audio${noCache ? "?x=" + Date.now() : ""}`);
    }
    return this.makeAssetUrl(`/api/recording/${recordingId}/${format}${noCache ? "?x=" + Date.now() : ""}`);
  },

  serializeRecordData(contentId, eventName, duration, recordingId, startTime = "", moderatorStartTime = "") {
    const durationMs = Math.ceil(duration * 1000);
    let recordDataXml = '<?xml version="1.0"?><recording >';
    recordDataXml += `<presentationContentId>${contentId}</presentationContentId>`;
    recordDataXml += `<duration>${durationMs}</duration>`;
    recordDataXml += `<eventName>${eventName.replace(/[<>]/g, ' ')}</eventName>`;
    recordDataXml += `<recordingId>${recordingId}</recordingId>`;
    recordDataXml += `<videoId>${recordingId}</videoId>`;
    recordDataXml += `<startTime>${startTime}</startTime>`;
    recordDataXml += `<moderatorStartTime>${moderatorStartTime}</moderatorStartTime>`;
    return recordDataXml + '</recording>';
  },

  serializeTimeline(timeline, recordingTime) {
    let timelineXml = '<?xml version="1.0"?><timeline xmlns="http://www.penxy.com/2012/xy/timeline"><version>1.0</version><questions /><points /><slides>';
    timeline.forEach((slide, i) => {
      const stopTime = i === timeline.length - 1 ? recordingTime : timeline[i + 1].time,
        normStartTime = Math.floor(slide.time * 1000),
        normStopTime = Math.floor(stopTime * 1000);
      if (normStartTime === normStopTime)
        console.error("[TIMELINE]", "zero duration slide recorded");
      timelineXml += `<slide num="${slide.slideNo}" start="${normStartTime}" stop="${normStopTime}" `;
      if (slide.videoAction) {
        timelineXml += `video="${slide.videoIndex}" action="${slide.videoAction}" `;
        if (slide.videoAction === "play" && slide.muted !== undefined)
          timelineXml += `muted="${slide.muted}" `;
      }
      timelineXml += "/>";
    });
    return timelineXml + '</slides></timeline>';
  },

  async exportRecording(alias, sessionId, eventId, exportMergeRatio = "1:1", mix = false, onlySlides = false) {
    const data = new URLSearchParams();
    data.set("permalink", alias);
    data.set("mergeRatio", exportMergeRatio);
    data.set("sessionId", sessionId);
    data.set("eventId", eventId);
    data.set("mix", mix);
    data.set("onlySlides", onlySlides);
    return fetch(config.apiExportHost + "/api/conversion", {
      method: "POST",
      body: data
    });
  },

  async deleteFromExportQueue(alias) {
    const data = new URLSearchParams();
    data.set("permalink", alias);
    return fetch(config.apiExportHost + "/api/conversion", {
      method: "DELETE",
      body: data
    });
  },

  getExportQueue({ eventId, sessionId } = {}) {
    let url = config.apiExportHost + "/api/conversions";
    if (eventId)
      url += `?eventId=${eventId}`;
    else if (sessionId)
      url += `?sessionId=${sessionId}`;
    return fetch(url).then(x => x.json());
  },

  startExportVm() {
    const state = fetch(config.apiExportHost + "/api/vm-status").then(x => x.json());
    if (state !== "running") {
      return fetch(config.apiExportHost + "/api/vm-start", {
        method: "POST"
      });
    }
  },

  getExportedDownloadUrl(data) {
    if (!data)
      return undefined;
    const { blobName: name } = data;
    // return `https://sspmp4exportst2.blob.core.windows.net/videos/${process.env.NODE_ENV}/${permalink}.mp4`;
    // return `https://sspmp4exportst2.blob.core.windows.net/videos/production/${encodeURIComponent(name)}`;
    return `https://ssp-mp4-export-cdn-prem.azureedge.net/videos/production/${encodeURIComponent(name)}`;
  },

  newReqPortal(method, path, data, tokenName = 'access_token', axiosOpts = {}) {
    let accessToken = "";
    try {
      accessToken = localStorage.getItem(tokenName);
    }
    catch (error) {
      console.log("API", "get token error", error);
    }
    const url = `${config.apiPortalHost}/api/${path}`;
    return axios({
      url,
      method,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      ...axiosOpts
    });
  },
};

window.api = Api;

export default Api;
